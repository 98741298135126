$story-text-size: 5.5vw;

%story-text {
  font-size: 6.5vw;
  text-align: justify;
  -webkit-font-smoothing: none;
  color: transparent;
  color: var(--text-colour);
  text-shadow: 0 0 var(--text-shadow-blur) var(--text-shadow-colour);
  text-transform: uppercase;
  font-family: Racing Sans One;
  font-weight: 900;
  filter: contrast(40) saturate(25);
  //filter: contrast(20) saturate(550);
  line-height: 1;

  @media (orientation: landscape) {
    font-size: 6.5vh;
  }
}
