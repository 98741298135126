@import '../story/_vars.scss';

.index__list {
  margin-top: 10vh;
  display: flex;
  flex-wrap: wrap;
}

.index__story {
  //width: percentage(1/5);
  //height: 100px;
  //display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 0.75em;
  margin-right: 1em;
  list-style: none;
}

.index__link {
  @extend %story-text;
  font-size: 5vw;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  @media (orientation: portrait) {
    font-size: 5vh;
  }

  .index__story--active & {
    //color: transparent;
    //animation: active 100ms infinite alternate;
    transform: scale(0.5);
    font-size: 0;

    &::before {
      content: '★';
      font-size: 5vw;
    }
  }
}

.index__nav {
  position: absolute;
  top: 10px;
  right: 15px;
}

.index__about {
  font-family: 'Racing Sans One';
  font-size: 25px;
  letter-spacing: 0.75px;
  color: grey;
}

@keyframes active {
  $colors-list: red pink purple yellow green cyan;
  @each $current-color in $colors-list {
    $i: index($colors-list, $current-color) * 10;
    $multiplier: 10 / length($colors-list);
    #{$i * $multiplier}% {
      text-shadow: 0 0 var(--text-shadow-blur) $current-color;
    }
  }
}
